<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '480px' }"
    header="Fomulário de Setores"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="field">
      <label for="nome">Nome</label>
      <InputText
        id="nome"
        v-model="v$.sector.name.$model"
        maxlength="100"
        placeholder="Digite o nome do setor"
        :class="{ 'p-invalid': submitted && v$.sector.name.$invalid }"
      />
      <small class="p-error" v-if="submitted && v$.sector.name.$invalid"
        >Nome do setor é obrigatório.</small
      >
    </div>
    <div class="field">
      <label for="sigla">Sigla</label>
      <InputText
        id="sigla"
        v-model="sector.acronym"
        maxlength="20"
        placeholder="Digite a sigla do setor"
      />
      <small
        v-if="submitted && v$.sector.acronym.maxLength.$invalid"
        class="p-error"
        >Sigla inválida. Máximo 20 caracteres</small
      >
    </div>

    <div class="field">
      <label for="tipoSetor">Tipo de Setor</label>
      <Dropdown
        id="tipoSetor"
        v-model="v$.sector.typeSector.$model"
        :options="tipoSetores"
        optionLabel="name"
        optionValue="key"
        placeholder="Selecione tipo do setor"
        :class="{ 'p-invalid': submitted && v$.sector.typeSector.$invalid }"
      />
      <small class="p-error" v-if="submitted && v$.sector.typeSector.$invalid"
        >Tipo do setor é obrigatório.</small
      >
    </div>

    <div class="field">
      <label for="tipoSetor">Status</label>
      <Dropdown
        id="tipoSetor"
        v-model="v$.sector.status.$model"
        :options="statusSetor"
        optionLabel="name"
        optionValue="key"
        placeholder="Selecione status do setor"
        :class="{ 'p-invalid': submitted && v$.sector.status.$invalid }"
      />
      <small class="p-error" v-if="submitted && v$.sector.status.$invalid"
        >Nome do setor é obrigatório.</small
      >
    </div>
    <div class="field">
      <label for="local">Local</label>
      <Dropdown
        id="local"
        v-model="v$.sector.local.$model"
        optionLabel="name"
        :options="locals"
        :filter="true"
        placeholder="Selecione o local"
        :class="{
          'p-invalid': submitted && v$.sector.local.$invalid,
        }"
      />
      <small class="p-error" v-if="submitted && v$.sector.local.$invalid"
        >Local é obrigatório.</small
      >
    </div>

    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.sector.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Setor from "../../../models/sector";

//ENUM
import { TipoSetor } from "../../../models/enums/tipo_setor.js";
import { Status } from "../../../models/enums/status";

//Services
import SectorService from "../../../service/sector/sector";
import LocalService from "../../../service/local/local";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["sectorSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      sector: new Setor(),
      submitted: false,
      sectorService: new SectorService(),
      locals: [],
      localService: new LocalService(),
      tipoSetores: TipoSetor,
      statusSetor: Status,
    };
  },
  mounted() {
    this.findLocals();
  },
  validations() {
    return {
      sector: new Setor().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.sector.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.sector.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.sector.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.sectorService
        .create(this.sector)
        .then((data) => {
          this.$msgSuccess(data);
          this.$emit("findAll");
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.sectorService
        .update(this.sector)
        .then((data) => {
          this.$msgSuccess(data);
          this.$emit("findAll");
          this.hideDialog();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.sector = new Setor();
      this.submitted = false;
      this.visibleDialog = false;
    },
    getData() {
      this.sector = this.sectorSelected;
    },
    findLocals() {
      this.localService
        .findAll()
        .then((response) => {
          this.$Token.checkAdmin().then((result) => {
            this.locals = result ? response : this.$Token.jwtDecode().local;
          });
        })
        .catch((error) => this.$msgErro(error));
    },
  },
};
</script>
<style scoped></style>
