import Local from "./local";
import { required, maxLength} from "@vuelidate/validators";

export default class Sector {
    constructor() {
        this.id = null;
        this.name = null;
        this.acronym = null;
        this.typeSector = null;
        this.status =  true;
        this.local = new Local();
    }
    validations() {
        return {
            name: {
                required,
            },
            acronym: {
                maxLength: maxLength(20),
            },
            typeSector: {
                required,
            },
            status: {
                required,
            },
            local: {
                name: {
                    required,
                }
            },
        
        };
    }
}