import axios from "axios";
import { gerarUrl} from  "../../utilities/utils/Pageable";
import AuthService from "../auth/auth_service";

export default class SectorService extends AuthService {
  constructor() {
    super("sector")
  }
  
  findAll(queryParams) {

    queryParams = gerarUrl(queryParams);

    return axios({
      method: "get",
      url: this.api_url + "sector?" + queryParams,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  findByLocalId(localId){
    return axios({
      method: "get",
      url: this.api_url + "sector/local/" + localId,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }

}
